import React, { useState } from "react";

import axios from "axios";

import cross from "../assets/cross.png";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPopups, setShowPopups] = useState(false);

  const clearForm = () => {
    setEmail("");
    setName("");
    setMessage("");
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("https://send-email.evandrarf.my.id/send-email", {
        name,
        email,
        text: message,
        subject:
          "You've got a message from your website Siteskuu by " +
          name +
          " <" +
          email +
          ">",
      })
      .then(
        (response) => {
          setLoading(false);
          console.log("SUCCESS!", response.status, response.text);
          setIsSuccess(true);
          setShowPopups(true);
          clearForm();
        },
        (error) => {
          setLoading(false);
          setIsSuccess(false);
          setShowPopups(true);
          setErrorMessage(error.text);
          console.log("FAILED...", error);
        }
      );
  };
  return (
    <>
      <div
        className="px-10 md:px-24 mt-48 flex flex-col items-center w-full"
        id="contact"
      >
        <h1 className="font-semibold text-4xl">Concact Us</h1>
        <form className="w-full mt-12" onSubmit={handleContactSubmit}>
          <div className="mb-6 flex flex-col md:flex-row  w-full">
            <div className="w-full mb-4 md:mb-0 mr-8">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                disabled={loading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="name@siteskuu.com"
                required
              />
            </div>
            <div className=" w-full">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                disabled={loading}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter Your Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Your Message
            </label>
            <textarea
              id="message"
              name="message"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Enter Your Message"
              required
              disabled={loading}
              rows={10}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            {loading ? "Sending Email..." : "Submit"}
          </button>
        </form>
      </div>
      <div
        className={`w-full top-0 z-10 min-h-screen ${
          showPopups ? "flex" : "hidden"
        } justify-center items-center fixed bg-black/50`}
      >
        <div className="w-3/4 md:w-1/2 md:py-8 md:px-12 rounded-md shadow-md min-h-fit py-4 px-6 bg-white">
          <div className="w-full flex justify-between items-center mb-5">
            <h3
              className={`${
                isSuccess ? "text-green-500" : "text-red-500"
              } mr-3 text-lg`}
            >
              {isSuccess
                ? "Success Sending a Message"
                : "Failed to Send a Message"}
            </h3>
            <img
              src={cross}
              alt=""
              className="h-4 w-4 cursor-pointer self-start"
              onClick={() => setShowPopups(false)}
            />
          </div>
          <p className="text-gray-400">
            {isSuccess
              ? "You've been successfully sending a message"
              : errorMessage}
          </p>
        </div>
      </div>
    </>
  );
};

export default Contact;
