import React from "react";

import { useState } from "react";

import Logo from "../assets/logo.png";
import humberger from "../assets/humberger-menu.png";
import cross from "../assets/cross.png";

const sidebarActive =
  "fixed md:w-2/3 w-screen px-4 py-12 z-10 bg-white transition-all ease-in-out duration-250 right-0 top-0 h-screen";
const sidebarNotActive =
  "fixed md:w-2/3 w-screen px-4 py-12 z-10 bg-white transition-all ease-in-out duration-500 -right-1000px top-0 h-screen";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const goToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const scrollToElement = (keyword) => {
    const element = document.getElementById(keyword);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setNavbar(false);
  };

  const closeSidebar = () => {
    setNavbar(false);
  };

  const openSidebar = () => {
    setNavbar(true);
  };

  return (
    <>
      <div
        className="lg:flex hidden justify-between w-full h-16  px-24 py-12"
        id="home"
      >
        <span onClick={goToTop} className="flex items-center cursor-pointer">
          <img src={Logo} alt="" className="h-8 mr-2" />
          <span className="font-semibold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
            Siteskuu
          </span>
        </span>

        <div className="flex items-center font-medium">
          <span
            onClick={() => scrollToElement("about")}
            className="mr-12 text-slate-100 cursor-pointer"
          >
            About
          </span>
          <span
            onClick={() => scrollToElement("feature")}
            className="mr-8 text-slate-100 cursor-pointer"
          >
            Features
          </span>
          <span
            onClick={() => scrollToElement("contact")}
            className="mr-16 rounded-sm px-4 py-2 border border-slate-100 text-slate-100 hover:bg-white hover:text-slate-800 hover:border-white hover:shadow-sm transition ease-in-out duration-500 cursor-pointer"
          >
            Contact
          </span>
        </div>
      </div>
      <div className="w-full px-12 py-6 flex justify-between lg:hidden">
        <span onClick={goToTop} className=" items-center cursor-pointer">
          <img src={Logo} alt="" className="h-6 md:8 mr-2   " />
          <span className="font-semibold text-transparent text-xl md:text-2xl bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
            Siteskuu
          </span>
        </span>
        <div>
          <img
            src={humberger}
            onClick={openSidebar}
            alt=""
            className="h-16 cursor-pointer"
          />
        </div>
      </div>
      <div className={navbar ? sidebarActive : sidebarNotActive}>
        <div className="flex justify-between items-center mb-10 border-b-2 border-gray-300 pb-8">
          <div className="flex">
            <img src={Logo} alt="" className="h-6 md:8 mr-2   " />
            <h1 className="font-semibold text-transparent text-xl bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500">
              Sideskuu
            </h1>
          </div>
          <img
            src={cross}
            alt=""
            onClick={closeSidebar}
            className="h-6 w-6 cursor-pointer"
          />
        </div>
        <div className="flex items-center text-center flex-col font-medium h-1/2 justify-evenly">
          <span
            onClick={() => scrollToElement("about")}
            className=" text-slate-900 cursor-pointer"
          >
            About
          </span>
          <span
            onClick={() => scrollToElement("feature")}
            className=" text-slate-900 cursor-pointer"
          >
            Features
          </span>
          <span
            onClick={() => scrollToElement("contact")}
            className=" rounded-sm px-4 py-2 border border-slate-900 text-slate-900 hover:bg-slate-900 hover:text-slate-100 hover:border-slate-900 hover:shadow-sm transition ease-in-out duration-500 cursor-pointer"
          >
            Contact
          </span>
        </div>
      </div>
    </>
  );
};

export default Navbar;
