import React from "react";
import Card from "./Card";

import hosting from "../assets/hosting.png";
import domain from "../assets/domain.png";
import email from "../assets/email.png";

const features = [
  {
    title: "Free Web Hosting",
    image: hosting,
    description: "Free website upload to hosting service",
  },
  {
    title: "Free Domain",
    image: domain,
    description: "Free custom domain for your website",
  },
  {
    title: "Free Custom Email",
    image: email,
    description: "Get a total of 50 custom emails for free.",
  },
];

const Feature = () => {
  return (
    <div className="px-24 flex flex-col items-center relative" id="feature">
      <h2 className="font-semibold text-4xl mb-6">Features</h2>
      <p className="text-gray-700">The advantages of our products</p>
      <div className="flex md:justify-evenly items-center flex-col md:flex-row w-full">
        {features.map((feature, index) => (
          <Card
            key={feature.title + index}
            title={feature.title}
            image={feature.image}
            description={feature.description}
          />
        ))}
      </div>
      <div className="absolute h-56 w-2000px bg-gradient-to-r from-sky-500 to-indigo-500 -rotate-12 top-60 -left-96 -z-10"></div>
    </div>
  );
};

export default Feature;
