import React from "react";

import firstSection from "../assets/first-section.png";

const TopSection = () => {
  return (
    <div className="px-8 md:px-24 flex w-full mt-24 md:mt-0 flex-col md:flex-row items-center justify-between">
      <div className="w-auto md:w-600px">
        <h1 className="font-bold text-3xl md:text-5xl grow mb-8 leading-tight">
          Own Your Website Cheaply, Quickly, and Easily
        </h1>
        <h3 className="md:text-lg text-base mb-10">
          Provides fast and inexpensive website development services
        </h3>
        <a
          href="https://wa.me/6289628115221"
          className="md:py-4 py-2 px-3 text-sm md:px-6 md:text-lg bg-blue-500 rounded text-white hover:bg-blue-600"
        >
          Get Started
        </a>
      </div>
      <div className="w-full mt-12 md:mt-0 md:w-600px">
        <img src={firstSection} alt="programmer" className="w-full h-full" />
      </div>
    </div>
  );
};

export default TopSection;
