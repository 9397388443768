import React from "react";

const MediaSocialLink = ({ image, url }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <img src={image} alt="social media" className="w-10 h-10 shadow-md" />
    </a>
  );
};

export default MediaSocialLink;
