import React from "react";

import { Link, useRouteError } from "react-router-dom";

import sorry from "../assets/sorry.png";

const ErrorPage = () => {
  const error = useRouteError();
  return (
    <div className="flex justify-center items-center flex-col p-32">
      <img src={sorry} alt="sorry" className="h-80" />
      <h1 className="font-bold text-5xl mb-8">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>
          {error.statusText} {error.message}
        </i>
      </p>
      <Link to={"/"} className="px-6 py-2 bg-blue-500 text-white rounded mt-6">
        Back to Home
      </Link>
    </div>
  );
};

export default ErrorPage;
