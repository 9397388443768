import React from "react";

import logo from "../assets/logo.png";

const About = () => {
  return (
    <div
      className="px-8 md:px-24  min-h-fit my-48 flex-col md:flex-row flex items-center justify-between relative"
      id="about"
    >
      <div className="absolute w-96 h-96 rounded-full bg-blue-500  -left-60 -top-72"></div>
      <div className="grow mb-12 md:mb-0">
        <img
          src={logo}
          alt="siteskuu logo"
          className="w-full max-h-32 md:max-h-max"
        />
      </div>
      <div className="w-2/3 ml-0 md:ml-16 flex flex-col">
        <div className="h-1 rounded-lg w-20 mb-4 bg-gradient-to-r from-cyan-500 to-blue-500"></div>
        <h2 className="font-semibold text-2xl mb-4">Siteskuu</h2>
        <p>
          Siteskuu is a software development company that provides web
          development services. We provide various services such as creating
          personal portfolios, company landing pages, creating APIs, and so on.
          We always try to provide the best service for our clients. With prices
          starting from IDR 75,000 you can already have the website you want.
        </p>
        <div className="h-1 rounded-lg self-end w-32 mt-4 bg-gradient-to-r from-cyan-500 to-blue-500"></div>
      </div>
    </div>
  );
};

export default About;
