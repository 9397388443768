import React from "react";
import About from "../components/About";
import Contact from "../components/Contact";
import Feature from "../components/Feature";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TopSection from "../components/TopSection";

const Home = () => {
  return (
    <div className="min-h-screen overflow-hidden relative">
      <div className="h-600 -top-1000px absolute -right-1/2 rotate-6 -z-10 w-full bg-blue-600 rounded-full"></div>
      <Navbar />
      <TopSection />
      <About />
      <Feature />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
