import React from "react";

const Card = ({ title, description, image }) => {
  return (
    <div className="flex flex-col items-center justify-between rounded-lg mt-24 bg-white px-12 py-10 h-96 shadow-md cursor-pointer hover:border-2 hover:shadow-2xl transition-all hover:bg-gray-50 hover:-translate-y-6 border-blue-300 w-80">
      <div className="w-24 h-24 flex justify-center items-center rounded-full bg-yellow-500 mb-10">
        <img src={image} alt={title} className="object-contain w-2/3" />
      </div>
      <div className="flex flex-col items-center">
        <h4 className="font-semibold text-lg mb-4">{title}</h4>
        <p className="text-center">{description}</p>
      </div>
    </div>
  );
};

export default Card;
