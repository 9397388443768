import React from "react";

import instagram from "../assets/instagram.png";
import telegram from "../assets/telegram.png";
import whatsapp from "../assets/whatsapp.png";
import youtube from "../assets/youtube.png";
import facebook from "../assets/facebook.png";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin.png";

import MediaSocialLink from "./MediaSocialLink";

const Footer = () => {
  const scrollToElement = (keyword) => {
    const element = document.getElementById(keyword);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <div className="w-full justify-between flex flex-col md:flex-row mt-32 p-8 md:p-24 text-white min-h-80 bg-gradient-to-l from-sky-500 to-indigo-500">
      <div className="flex flex-col mb-8 md:mb-0">
        <h3 className="font-bold text-2xl">Siteskuu</h3>
        <span
          className="cursor-pointer mt-6 hover:text-gray-100 hover:underline transition-all duration-300 ease-in-out"
          onClick={() => scrollToElement("home")}
        >
          Home
        </span>
        <span
          className="cursor-pointer mt-3 hover:text-gray-100 hover:underline transition-all duration-250 ease-in-out"
          onClick={() => scrollToElement("about")}
        >
          About
        </span>
        <span
          className="cursor-pointer mt-3 hover:text-gray-100 hover:underline transition-all duration-250 ease-in-out"
          onClick={() => scrollToElement("feature")}
        >
          Feature
        </span>
        <span
          className="cursor-pointer mt-3 hover:text-gray-100 hover:underline transition-all duration-300 ease-in-out"
          onClick={() => scrollToElement("contact")}
        >
          Contact
        </span>
      </div>
      <div className="flex mt-12 md:mt-0 flex-col">
        <h3 className="font-bold text-2xl">Contact Us!</h3>
        <div className="flex gap-5 mt-6 flex-wrap">
          <MediaSocialLink
            image={instagram}
            url="https://instagtam.com/siteskuu"
          />
          <MediaSocialLink image={twitter} url="https://twitter.com/siteskuu" />
          <MediaSocialLink
            image={linkedin}
            url="https://www.linkedin.com/company/siteskuu"
          />
          <MediaSocialLink image={telegram} url="https://t.me/siteskuuu" />
          <MediaSocialLink image={whatsapp} url="https://wa.me/6289628115221" />
          <MediaSocialLink
            image={youtube}
            url="https://youtube.com/@siteskuu"
          />
          <MediaSocialLink
            image={facebook}
            url="https://facebook.com/siteskuuofc"
          />
        </div>
        <span className="mt-4">Email : siteskuu@siteskuu.com</span>
      </div>
    </div>
  );
};

export default Footer;
